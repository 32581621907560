.ant-upload-list-picture-card-container,
.ant-upload.ant-upload-select-picture-card {
  width: 80px !important;
  height: 80px !important;
}
.ant-upload-list {
  width: 370px !important;
}
.ant-upload-span > a {
  width: 300px !important;
}
.ant-upload.ant-upload-drag .ant-upload{
  padding:8px 0px !important;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon{
  font-size: 30px !important;
}